// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useEffect, useState } = React;

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Button } = require('@andes/button');
const { Text, Title } = require('@andes/typography');
const ChevronRight16 = require('@andes/icons/ChevronRight16');

const { getProductLink } = require('@services/user');

const BASE_CLASS_NAME = 'productDetails';

const ProductDetails = ({ isMobile, nickname, product, siteId }) => {
  const { i18n } = useI18n();

  const [link, setLink] = useState(undefined);

  const fetchLink = async () => {
    try {
      const respLink = await getProductLink(product.buId, nickname);

      setLink(respLink?.link);
    } catch (error) {
      setLink(undefined);
    }
  };

  useEffect(() => {
    if (product.buId) {
      fetchLink();
    }
  }, [product, nickname]);

  if (isMobile) {
    return (
      <div className={BASE_CLASS_NAME} data-siteId={siteId}>
        <Text className={`${BASE_CLASS_NAME}-off`} component="strong" size="xs">
          {product.tag}
        </Text>
        <Title className={`${BASE_CLASS_NAME}-description`} component="h3">
          {i18n.gettext(product.name)}
        </Title>
        {product.image && (
          <Image
            lazyload
            className={`${BASE_CLASS_NAME}-image`}
            src={`certificate/${product.image}`}
          />
        )}
        {product.button && (
          <Button
            fullWidth
            className={`${BASE_CLASS_NAME}-button`}
            hierarchy="transparent"
            href={link}
            target="_blank"
          >
            {i18n.gettext(product.button.text)}
            <ChevronRight16 fill="#009ee3" />
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={BASE_CLASS_NAME} data-siteId={siteId}>
      {product.image && (
        <Image
          lazyload
          className={`${BASE_CLASS_NAME}-image`}
          src={`certificate/${product.image}`}
        />
      )}
      <div className={`${BASE_CLASS_NAME}-texts`}>
        <Text className={`${BASE_CLASS_NAME}-off`} component="strong" size="xs">
          {product.tag}
        </Text>
        <Title className={`${BASE_CLASS_NAME}-description`} component="h3" size="xl">
          {i18n.gettext(product.name)}
        </Title>
        {product.button && (
          <Button className={`${BASE_CLASS_NAME}-button`} href={link} target="_blank">
            {i18n.gettext(product.button.text)}
          </Button>
        )}
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  isMobile: PropTypes.bool,
  nickname: PropTypes.string,
  product: PropTypes.shape({
    name: '',
    button: {},
    tag: '',
    image: '',
    buId: 0,
  }),
  siteId: PropTypes.string,
};

module.exports = ProductDetails;
