// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { Typography } = require('@andes/typography');

const ProductDetails = require('../product-details');

const configs = require('./configs');

const CredentialsBody = ({ isMobile, nickname, siteId }) => {
  const { i18n } = useI18n();

  const config = configs[siteId || 'MLA'](i18n, isMobile);

  return (
    <section className="credentials-body">
      <div className="top-border" />
      {config?.title && (
        <Typography className="credentials-body-title" component="h2" type="title">
          {config?.title}
        </Typography>
      )}
      {config?.activeProducts?.map((product) => (
        <ProductDetails
          key={product}
          isMobile={isMobile}
          nickname={nickname}
          product={config.products[product]}
          siteId={siteId}
        />
      ))}
    </section>
  );
};

CredentialsBody.propTypes = {
  isMobile: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  nickname: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

module.exports = CredentialsBody;
