// i18nLocalesOverride:['pt-BR']

module.exports = (i18n, isMobile) => {
  return {
    title: i18n.gettext('Aprovecha los beneficios exclusivos ofrecidos por tu revendedor'),
    activeProducts: ['POINT'],
    products: {
      LINK_DE_PAGO: {
        buId: 4,
        id: 'LINK_DE_PAGO_ECO',
        name: i18n.gettext('Comparte el link de pago y vende desde donde estés'),
        tag: i18n.gettext('20% de descuento en tasas'),
        image: 'link-de-pago-mlb.png',
        button: {
          text: i18n.gettext('Conocer link de pago'),
          url: '',
        },
      },
      QR: {
        buId: 2,
        id: 'QR_CODE_ECO',
        name: i18n.gettext('Ofrece pagos con el QR impreso y facilita las ventas en tu comercio'),
        tag: i18n.gettext('Tasa 0% por 90 días'),
        image: 'qr-mlb.png',
      },
      POINT: {
        id: 'POINT',
        name: i18n.gettext(
          'Vende con Point y elige la tarifa y cuándo quieres recibir el dinero de tus ventas',
        ),
        tag: i18n.gettext('Sin mensualidades'),
        image: isMobile ? 'point-mlb-mobile.png' : 'point-mlb.png',
      },
    },
  };
};
