// i18nLocalesOverride:['es-MX']

module.exports = (i18n) => {
  return {
    title: i18n.gettext('Disfruta de los beneficios exclusivos que tu representante te ofrece'),
    activeProducts: ['POINT'],
    products: {
      QR: {
        buId: 2,
        id: 'QR_CODE_ECO',
        name: i18n.gettext('Ofrece pagos con el QR impreso y facilita las ventas en tu tienda'),
        tag: i18n.gettext('Tasa 0% por 1 mes'),
        image: 'new-qr-mlm.png',
      },
      LINK_DE_PAGO: {
        buId: 4,
        id: 'LINK_DE_PAGO_ECO',
        name: i18n.gettext('Comparte el enlace de link de pago y vende donde quiera que estés'),
        tag: i18n.gettext('40% de descuento en tasas'),
        image: 'link-de-pago-mlm.png',
        button: {
          text: i18n.gettext('Conocer link de pago'),
          url: '',
        },
      },
      POINT: {
        id: 'POINT',
        name: i18n.gettext('Cobra con Point y recibe el dinero de todas las ventas al instante'),
        tag: i18n.gettext('Tasa 0% por 30 días'),
        image: 'point-mlm.png',
      },
    },
  };
};
